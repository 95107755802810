<template>
    <UDashboardLayout>
        <UDashboardPage>
            <UDashboardPanel grow>
                <UDashboardNavbar title="NomadRx">
                    <template #title>
                        <NuxtLink to="/">
                            <p class="text-xl font-bold tracking-tight">NomadRx</p>
                        </NuxtLink>

                    </template>
                    <template #toggle>
                        <UDashboardNavbarToggle class="hidden" />
                    </template>
                    <!-- <template #right>
                        <UButton icon="i-heroicons-language" color="gray" variant="ghost" />
                    </template> -->
                </UDashboardNavbar>
                <UDashboardPanelContent>
                    <div class="flex justify-center items-center mt-12">
                        <slot />
                    </div>
                </UDashboardPanelContent>
            </UDashboardPanel>
        </UDashboardPage>
    </UDashboardLayout>
</template>